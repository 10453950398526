<template>
  <navigation-tabs>
  <v-container class="d-flex fill-height align-start mt-4 px-8">
    <v-row class="pt-4 px-4">
      <h5 class="text-h5 el_centered_h5">住宅の建て⽅を選択してください。</h5>
    </v-row>
    <v-col cols=12 align="center" class="mt-8 mb-6 pa-0">
      <v-card
        width=100%
        class="px-8 py-4"
        outlined>
        <v-card-title>
          <v-icon>mdi-alert-circle</v-icon>住宅の建て⽅の確認方法
        </v-card-title>
        <v-card-text
          class="text-justify">
          <p>計算結果PDFの1ページ目「(1) 住宅タイプの名称 ( 建て方)」という項目で「戸建住宅」か「共同住宅」かを確認できます。</p>
        </v-card-text>
      </v-card>
    </v-col>

    <v-row class="mb-12 d-flex align-center justify-center" >
      <v-col v-for="(type, index) in householdTypes"
        :key="index"
        cols="12"
        md="4"
        align="center"
        class="el_btnHouseType"
        :class="'el_btnHouseType__' + index"
        style="flex-grow: 0;">
        <v-btn
        v-if="type == '建売戸建住宅' || type == '注文戸建住宅'"
        @click="selectHouseholdType(type)"
          width="100%"
          max-width="100%"
          height="120"
          color="#5E539A">
            <span class="text-h5 white--text">{{ type }}</span>
        </v-btn>
        <v-btn
        v-else-if="type == '賃貸アパート・分譲マンション'"
        @click="selectHouseholdType(type)"
          width="100%"
          max-width="100%"
          height="120"
          color="#5E539A">
            <span class="text-h5 white--text">賃貸アパート・<br />分譲マンション</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mx-0 mt-10 pa-0 bl_fixedFooter">
      <v-btn
       to="/terms"
       plain
       height="60"
       class="font-weight-bold text-h6 pl-0">
        <v-icon large>mdi-chevron-left</v-icon>
        戻る
      </v-btn>
    </v-row>
  </v-container>

  </navigation-tabs>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import NavigationTabs from '@/components/templates/NavigationTabs.vue'

export default {
  components: {
    NavigationTabs,
  },
  computed:{
    ...mapGetters(['householdTypes'])
  },

  methods: {
    ...mapActions(['update']),

    // 住宅の建て⽅を選択する処理
    selectHouseholdType(type) {
      this.update({selectedHouseholdType:type})
      this.$router.push('/report-type')
    }
  }
}
</script>

<style>
.el_btnHouseType{
  
}
.el_btnHouseType>button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: .25rem;
}
.el_btnHouseType>button::before{
  content:"";
  display: inline-flex;
  width: 92px;
  height: 26px;
  background: url(../assets/img/iconset.png) no-repeat;
  background-size: 100% auto;
  opacity: 1;
  position: relative;
}

.el_btnHouseType.el_btnHouseType__1>button::before{
  background-position: 0 -32px;
}
.el_btnHouseType.el_btnHouseType__2>button::before{
  background-position: 0 -64px;
}
.el_btnHouseType>button.v-btn:hover::before{
  opacity:0.7;
}
.el_btnHouseType>button>span{
  flex: unset;
}
.el_btnHouseType>button>span>span{
  line-height: 1 !important;
}
</style>